module.exports = {
  siteTitle: "Astrospective's Blog", // Site title.
  siteTitleShort: "Astro blog", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Astro's Blog", // Alternative site title for SEO.
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://www.astrospective.com", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-material-starter/.
  fixedFooter: false, // Whether the footer component is fixed, i.e. always visible
  siteDescription: "A GatsbyJS stater with Material design in mind.", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteFBAppID: "", // FB Application ID for using app insights
  siteGATrackingID: "", // Tracking code ID for google analytics.
  disqusShortname: "", // Disqus shortname.
  postDefaultCategoryID: "Tech", // Default category for posts.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  userName: "Astrospective", // Username to display in the author segment.
  userLocation: "Peoria, AZ", // User location to display in the author segment.
  userAvatar: "https://api.adorable.io/avatars/150/test.png", // User avatar to display in the author segment.
  userDescription:
    "I'm a Dad, developer, practice jujitsu and a table top gamer. I've been dabbling in Elixir, Go, and leveling up my JS skills. Dogs are the best.", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "GitHub",
      url: "https://github.com/astrospective/blog",
      iconClassName: "fa fa-github"
    },
    {
      label: "Mastodon",
      url: "https://wandering.shop/@Astrospective",
      iconClassName: "fab fa-mastadon"
    }
  ],
  copyright: "Copyright © 2018." // Copyright string for the footer of the website and RSS feed.
};
